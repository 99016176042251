
/* ==========================================================================
    #HEADER BIG
========================================================================== */

// HEADER
.header_big__container{
    margin-bottom: $inuit-global-spacing-unit;

    .header_big__videocontainer{
        position: relative;
        overflow: hidden;
        width: 100vw;
        height: 400px;
        // background-color: $gray;

        display: flex;
        justify-content: center;
    }

    .header_big__contentcontainer{
        // padding-bottom: $inuit-global-spacing-unit-large;
        position: absolute;
        z-index: 100;
        height: 400px;
        width: 100vw;

        display: flex;
        align-items: flex-end;

        .o-wrapper{
            left: auto;
            right: auto;
            padding-bottom: $inuit-global-spacing-unit-small;
        }

        h1{
            width: 100%;
            color: $white;
            font-size: 35px;
            font-weight: $font-weight-black;
            margin-bottom: $inuit-global-spacing-unit-small;
        }
    }
}

@include mq($from: mobile) {
    .header_big__container{
        .header_big__contentcontainer{
            h1{
                max-width: 50%;
                font-size: 45px;
            }
        }
    }
}

@include mq($from: tablet) {
    .header_big__container{
        .header_big__videocontainer{
            height: 550px;
        }
        .header_big__contentcontainer{
            height: 550px;
            // margin-bottom: $inuit-global-spacing-unit;

            .o-wrapper{
                padding-bottom: $inuit-global-spacing-unit;
            }
    
            h1{
                max-width: 70%;
                font-size: 65px;
            }
        }
    }
}

@include mq($from: desktop) {
    .header_big__container{  
        margin-bottom: $inuit-global-spacing-unit-superhuge;

        .header_big__videocontainer{
            width: 100vw;
            height: 750px;
        }

        .header_big__contentcontainer{
            height: 750px;

            .o-wrapper{
                padding-bottom: $inuit-global-spacing-unit-huge;
            }
        }

        .header_big__contentcontainer{
            margin-bottom: $inuit-global-spacing-unit-superhuge;

            h1{
                font-size: 90px;
            }
        }
    }
    
}

/* ==========================================================================
    #VIDEO
========================================================================== */

.header_big__videocontainer{
    position: absolute;
    width: 100%;
    height: auto;

    video{
        display: inline-block;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
        background-color: black;

        // @include mq($from: desktop) {
        //     width: 100%;
        //     height: auto;
        // }

        @include mq($from: 1220px) {
            width: auto;
            height: 100%;
        }

        @include mq($from: 1350px) {
            width: 100%;
            height: auto;
        }
    }

    .videoOverlay{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: RGBA(0, 0, 0, 0.80);

    }
}

/* ==========================================================================
    #HEADER SMALL
========================================================================== */

.header_small__container{
    margin-top: 130px;
    
    p, h1{
        max-width: 100%;
    }

    .header_small_introtext{
        font-size: 20px;
    }
}

.header_small__container.detailpage .header_small__headergroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .header_small__titlegroup{
        order: 1;
    }

    .header_small__taggroup{
        order: 0;
    }

    ul{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 0px;
    }

    ul li {
        color: $white;
        background-color: $brand-primary;
        padding: 0px 3px;
        line-height: 120%;
        font-weight: $font-weight-bold;
        margin-top: 5px;
        display: inline-block;
        margin-left: 0px;
        margin-right: $inuit-global-spacing-unit-tiny;
        
        &:before{
            display: none;
        }

        a, a:hover{
            color: $white;
            text-decoration: none;
        }
    }

    h1{
        margin-top: $inuit-global-spacing-unit-small;
        margin-bottom: $inuit-global-spacing-unit-tiny;
        order: 1;
        font-weight:   $font-weight-black;
    }

    .date{
        color: $white;
        background-color: $brand-primary;
        padding: 0px 3px;
        display: inline-block;
        line-height: 120%;
        font-weight: $font-weight-bold;
    }

}

.header_small__clientname{
    color: $brand-primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $inuit-global-spacing-unit-small;
}

@include mq($from: tablet) {
    .header_small__container{
        margin-top: 170px;

        p, h1{
            max-width: 66%;
        }
    }

    .header_small__container.detailpage .header_small__headergroup{
        // flex-direction: row;
        // align-items: flex-end;

        .header_small__titlegroup{
            order: 1;
        }

        .header_small__taggroup{
            order: 0;
        }

        ul{
            flex-direction: column;

            li{
                align-self: flex-start;
                // margin-left: $inuit-global-spacing-unit-tiny;
            }
        }

        h1{
            order: 0;
        }
    }
}


@include mq($from: desktop) {
    .header_small__container{
        // p, h1{
        //     max-width: 55%;
        // }
    }
}
