
.detailpage__content__container{
    padding-bottom: $inuit-global-spacing-unit-small;
    // border-bottom: 1px solid $brand-primary;
}

@include mq($from: tablet) {
    .detailpage__content__container{
        padding-bottom: $inuit-global-spacing-unit-huge;
    }
}

/* ==========================================================================
    #DETAILPAGE HEADER
========================================================================== */

.detailpage__headerimage__container{
    width: 100%;
    margin-top: $inuit-global-spacing-unit-small;
    margin-bottom: $inuit-global-spacing-unit-small;

    img{
        position: abolute;
    }
}

@include mq($from: tablet) {
    .detailpage__headerimage__container{
        margin-top: $inuit-global-spacing-unit-large;
        margin-bottom: $inuit-global-spacing-unit-large;
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - HEADERS
========================================================================== */
// .detailpage__header__container{
//     display: flex;
//     flex-flow: column wrap;
//     align-items: space-between;

//     margin-top: $inuit-global-spacing-unit-small;
//     margin-bottom: $inuit-global-spacing-unit-small;

//     .detailpage__header__subheading{
//         flex: 1 0 33%;
//         border-right: 20px solid $white;

//         p{
//             color: $brand-primary;
//             font-weight: $font-weight-regular;
//             text-transform: uppercase;
//             letter-spacing: 1px;
//             margin-bottom: $inuit-global-spacing-unit-tiny;

//             &:before{
//                 content: '/ ';
//                 color: $brand-primary;
//             }
//         }
//     }

//     .detailpage__header__header{
//         flex: 1 0 66%;

//         h2{
//             margin-bottom: 0px;
//         }
//     }
// }

// @include mq($from: mobile) {
//     .detailpage__header__container{
//         flex-flow: row wrap;
//     }
// }
.detailpage__columns__innercontainer{
    h2{
        margin-bottom: 0px;
        padding-right: $inuit-global-spacing-unit-small;
    }

    p{
        margin-bottom: 0px;
        padding-right: $inuit-global-spacing-unit-small;
    }

    .detailpage__header__subheading{
        color: $brand-primary;
        font-weight: $font-weight-regular;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: $inuit-global-spacing-unit-tiny;

        &:before{
            content: '/ ';
            color: $brand-primary;
        }
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - IMAGE/VIDEO
========================================================================== */

.detailpage__image__container, .detailpage__video__container{
    margin-bottom: $inuit-global-spacing-unit-small;

    .video__container{
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .video__container-no-iframe {
        video {
            width: 100%;
        }
    }
}

/* ==========================================================================
    #DETAILPAGE COMPONENTS - QUOTES
========================================================================== */
.detailpage__columns__innercontainer{
    .detailpage__quote__container{
        width: 100%;
        min-width: 300px;

        font-weight: $font-weight-black;
        font-size: 30px;
        color: $brand-primary;
        
        margin-top: $inuit-global-spacing-unit-small;

        .detailpage__quote__innercontainer{
            // max-width: 85%;
            position: relative;
            padding-top: 10px;
            padding-right: $inuit-global-spacing-unit-small;

            p{
                padding-top: 10px;
            }

            &:before{
                content: '';
                position: absolute;
                display: inline-block;
                width: 95%;
                height:8px;
                top: 0px;
                background-color: $brand-primary;;
            }
        }

        span{
            font-weight: $font-weight-regular;
            color: $gray;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

@include mq($from: tablet) {
    .detailpage__columns__innercontainer{
        .detailpage__quote__container{
            margin-top: -10px;
        }
    }
}


/* ==========================================================================
    #DETAILPAGE COLUMNS
========================================================================== */
// ALL COLUMNS
.detailpage__columns__innercontainer{
    padding: 0px;
    padding-bottom: $inuit-global-spacing-unit-tiny;
}

// TWO COLUMN
.detailpage__twocolumn__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;

    &.small-big{
        .detailpage__twocolumn__container__innercontainer:first-child{
            width: 100%;
        }
    
        .detailpage__twocolumn__container__innercontainer:last-child{
            width: 100%;
        }
    }

    &.big-small{
        .detailpage__twocolumn__container__innercontainer:first-child{
            width: 100%;
        }
    
        .detailpage__twocolumn__container__innercontainer:last-child{
            width: 100%;
        }
    }
}

// THREE COLUMN
.detailpage__threecolumn__container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px;

    .detailpage__threecolumn__container__innercontainer{
        width: 100%;
    }
}

@include mq($from: tablet) {
    // TWO COLUMN
    .detailpage__twocolumn__container{
        flex-direction: row;
        margin-bottom: $inuit-global-spacing-unit-small;

        &.small-big{
            .detailpage__twocolumn__container__innercontainer:first-child{
                width: 33%;
            }
        
            .detailpage__twocolumn__container__innercontainer:last-child{
                width: 66%;
            }
        }

        &.big-small{
            .detailpage__twocolumn__container__innercontainer:first-child{
                width: 66%;
            }
        
            .detailpage__twocolumn__container__innercontainer:last-child{
                width: 33%;
            }
        }
    }


    // THREE COLUMN
    .detailpage__threecolumn__container{
        flex-direction: row;
        margin-bottom: $inuit-global-spacing-unit-small;

        .detailpage__threecolumn__container__innercontainer{
            width: 32.8%;
        }
    }

/* ==========================================================================
    #DETAILPAGE COMPONENTS - SPACER
========================================================================== */

    .spacer-desktop, .spacer-tablet{
        display: none;
    }

    .spacer-mobile{
        display: block;
    }

    @include mq($from: tablet) {
        .spacer-tablet{
            display: block;
        }
        
        .spacer-mobile, .spacer-desktop{
            display: none;
        }
    }

    @include mq($from: desktop) {
        .spacer-desktop{
            display: block;
        }
        
        .spacer-mobile, .spacer-tablet{
            display: none;
        }
    }

}

.cta__container {
    // background-color: #eb2226;
    padding: 50px;
    color: white;
    position: relative;
    z-index: 1;
    a, p, h2 {
        color: white;
    }
    h2 {
        margin-bottom: 20px;
        max-width: 420px;
    }
    p {
        font-size: 18px;
    }
    .cta__inner-container {
        width: 100%;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #eb2226;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -2;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        background-image: url('https://fisheye.eu/assets/fisheye-lens.svg');
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: 110% center;
        background-size: auto 140%;
        opacity: 0.6;
        z-index: -1;
    }
    a.button {
        background-color: transparent;
        padding: 15px 20px;
        color: #2D2134;
        font-weight: 600;
        display: inline-block;
        font-size: 18px;
        margin-right: 10px;
        position: relative;
        transition: all 0.6s ease;
        text-decoration: none;
        z-index: 1;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #2D2134;
            transition: all 0.6s ease;
            z-index: -1;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: white;
            z-index: -2;
        }
        &:hover {
            color: white;
            text-decoration: none;
            &::after {
                top: 0;
            }
        }
    }
}

@include mq($from: desktop) {
    .cta__inner-container {
        width: 50%;
    }
    .cta__container::after {
        background-position: 105% center;
        background-size: auto 160%;
    }
}

.video__container-no-iframe {
    display: inline-block;
}

@include mq($from: desktop) {
    .video__container-no-iframe {
        display: flex;
        justify-content: flex-end;
    }
}

.video__container-no-iframe, .video__container-no-iframe video {
    // width: 100%;
    max-width: 100%;
    position: relative;
    max-height: 100vh;
    &:hover {
        cursor: pointer;
        .video__button {
            // background-color: rgba(255,255,255,1);
            transform: scale(1.2, 1.2);
            &:after {
                // border-left: 60px solid rgb(0, 0, 0, 1);
            }
        }
    }
}

.video__button {
    z-index: 99;
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    width: 150px;
    height: 150px;
    border: 5px solid white;
    border-radius: 50%;
    background-color: rgba(255,255,255,0);
    color: white;
    transition: all 0.6s ease;
    pointer-events: none;
    &.hideButton {
        display: none;
    }
    &:after {
        content: '';
        display: block;
        width: 0; 
        height: 0; 
        border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        
        border-left: 60px solid rgba(255,255,255,1);
        transform: scale(1,0.7);
        margin: 0 30px 0 45px;
    }
}

// MASONRY

.masonry-wrapper {
    margin-left: -10px;
}

.masonry-grid {
    width: 100%;
}

.grid__item--square {
    .masonry-image-ratio {
        padding-bottom: 100%;
    }
}

.grid__item--horizontal {
    .masonry-image-ratio {
        padding-bottom: 75%;
    }
}

.grid__item--vertical {
    .masonry-image-ratio {
        padding-bottom: 150%;
    }
}

.masonry-image-ratio {
    max-width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    img, video {
        position: absolute;
        top: 0px;
        bottom: 10px;
        left: 10px;
        right: 0px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        object-fit: cover;
        max-width: 100%;
    }
}
